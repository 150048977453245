div.mainpage {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.mainpage div.body {
    position: relative;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mainpage div.card {
    color: var(--textcolor);
    text-align: center;
}

.mainpage h1 {
    font-size: calc(7*((1vw + 1vh)/2));
    font-weight: bold;
}

.mainpage h2 {
    font-size: calc(3*((1vw + 1vh)/2));
}

.mainpage .logo {
    width: calc(4*((1vw + 1vh)/2));
    display: inline-block;

    transition: 0.2s;
}

.mainpage .card a.link{
    margin-left: calc(0.8*((1vw + 1vh)/2));
    margin-right: calc(0.8*((1vw + 1vh)/2));

    transition: 0.2s;
}

.mainpage .link:hover, .mainpage .logo:hover {
    transform: translateY(-10%);
}

.mainpage div.links {
    margin-top: 2%;
    display: inline-block;
}

.mainpage img.alert {
    animation: shake 2s infinite linear;
}

@keyframes shake {
    0% { transform: rotate(0deg); }
    5% { transform: rotate(0deg); }
    10% { transform: rotate(-10deg); }
    15% { transform: rotate(10deg); }
    20% { transform: rotate(-10deg); }
    25% { transform: rotate(10deg); }
    30% { transform: rotate(-10deg); }
    35% { transform: rotate(10deg); }
    40% { transform: rotate(0deg); }
    40% { transform: rotate(0deg); }
    50% { transform: rotate(0deg); }
    100% {}
  }