div.techstackpage {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: var(--textcolor);
}

.techstackpage div.body {
    position: relative;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    /*background-color: var(--backgrounddots);*/
    width: 80vw;
    height: 85vh;
    padding: 2%;
    border-radius: 16px;
}

.techstackpage div.tabs {
    margin-bottom: 3%;
}

.techstackpage div.stack {
    /*background-color: var(--background);*/
    padding-top: 65px;
    border-radius: 16px;
    width: 90%;
    height: 92%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    gap: 60px 100px;
    overflow-y: auto;
}

.techstackpage div.skill {
    flex-shrink: none;
    flex-grow: none;
    height:fit-content;
    width: 100px;
    text-align: center;
}

.techstackpage img.logo {
    display: inline;
    padding-bottom: 6px;

    width: 60px;
}

.techstackpage .skill h1.title {
    font-size: 16px;
}

@media only screen and (max-width: 670px) {
    .techstackpage div.skill {
      width: 30%
    }

    .techstackpage img.logo {
        width: 60%;
    }

    .techstackpage .skill h1.title {
        font-size: 80%;
    }

    .techstackpage div.stack {
        gap: 6% 5%;
    }
}