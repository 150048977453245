.filter {
    position: relative;
    left: 50%;
    transform: translatex(-50%);
    top: 90px;
    text-align: center;
    padding-bottom: 160px;
    padding-top: 1%;
    padding-left: 15px;
    padding-right: 15px;
}

.filterwrapper {
    flex-flow: row wrap;
    justify-content: center;
    white-space: nowrap;
}

.filterstackscroll {
    overflow: hidden;
}

@media only screen and (max-width: 600px) {
    .filterwrapper {
        flex-flow: row nowrap;
        justify-content: flex-start;
        padding-left: 10px;
        padding-right: 10px;
    }

    .filterstackscroll {
        overflow-x: auto;
        overflow-y: hidden;
        height: 130px;
    }
}

.projects {
    position: relative;
    left: 50%;
    transform: translatex(-50%);
    width: 80%;
    height: calc(100% - 300px);
    overflow-y: auto;
    color: var(--textcolor);
    padding-top: 1%;

    display: flex;
    flex-flow: row wrap;
    gap: 7%;
    justify-content: center;
    align-content: flex-start;
}

.project {
    align-items: center;
    width: 25%;
    height: fit-content;

    transition: width 0.15s;
}

.project:hover {
    width: 27%;
}

@media only screen and (max-width: 1100px) {
    .project {
        width: 40%
    }
    .project:hover {
        width: 40%;
    }
}

@media only screen and (max-width: 600px) {
    .project {
        width: 80%
    }
    .project:hover {
        width: 80%;
    }
}

.modal {
    background-color: var(--modalbackground);
    color: var(--textcolor);
}

.modalCloseButton:hover, .modalCloseButton:active {
    background-color: transparent;
}

.stack {
    display: inline-flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: flex-start;
    align-content: flex-start;
    height: 100px;
    overflow-x: auto;
    overflow-y: hidden;
}

.skill {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
}