:root {
    --background: #0d1116;
    --secondarybackground: #27272a;
    --backgrounddots: #725a5a;
    --textcolor: white;
    --modalbackground: #18181b;
}

::-webkit-scrollbar-thumb {
    background-color: #3f3f46;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

::-webkit-scrollbar {
    background-color: transparent;
  }

span.body {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}