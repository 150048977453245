div.navbar {
    position: absolute;
    z-index: 1;

    position: absolute;
    top: 1.5vh;
    left: 50%;
    transform: translatex(-50%);

    background-color: var(--navbarbackground);
}